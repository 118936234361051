import { BaseSeries } from 'entities/chart/model';
import { CategoryDiagramSettings } from 'entities/panel';
import { defined, isDefined } from 'shared/lib/checks';
import { getSeriesValue } from './getSeriesValue';
import { isPieChart } from './isPieChart';
import { isStackedChart } from './isStackedChart';
import { ValueResponse } from 'entities/dataValue/model/response';
import { getGroupValueSum } from './getGroupValueSum';

export type ApexChartRow = ApexAxisChartSeries[0];

export const parseSeries = (response: ValueResponse, config: CategoryDiagramSettings): BaseSeries[] | number[] => {
  const fractions = config.visuals.dataLabels?.value?.fraction ?? 0;
  const groups = response.byGroups;

  if (isPieChart(config)) {
    return groups[0]?.byIndicators.map((indicator) => getSeriesValue(indicator, fractions)) || [];
  }

  const getClassifierTitle = (innerId: string) => {
    return response.classifiers?.find((classifier) => classifier.innerId === innerId)?.title ?? '';
  };

  if (isStackedChart(config)) {
    const stackedResult: Record<string, BaseSeries> = {};

    const allNodes = groups.map((group) => group.nodeId);

    groups.forEach((group) => {
      group.byIndicators.forEach((indicator) => {
        if (indicator.nodeIds?.length) {
          const key = indicator.key;
          const name = indicator.nodeTitle ?? getClassifierTitle(defined(indicator.nodeIds[0]).nodeId);
          const data = [getSeriesValue(indicator, fractions)];

          if (isDefined(data[0])) {
            if (key in stackedResult && stackedResult[key]) {
              const item = stackedResult[key] as BaseSeries;
              item.data = [...item.data, data[0]];
            } else {
              stackedResult[key] = { name, data, nodes: allNodes };
            }
          }
        }
      });
    });

    return Object.values(stackedResult);
  }

  const resultObj: Record<string, BaseSeries> = {};
  for (const group of groups) {
    const groupSum = getGroupValueSum(group, fractions);

    if (!groupSum) {
      continue;
    }

    group.byIndicators.forEach((indicator) => {
      const key = indicator.key;
      const name = indicator.nodeTitle ?? getClassifierTitle(defined(indicator.nodeIds?.[0]).nodeId);

      const value = {
        y: getSeriesValue(indicator, fractions),
        x: name,
        meta: { groupId: group.nodeId || '' },
      };

      if (isDefined(value.y)) {
        if (key in resultObj && resultObj[key]) {
          const item = resultObj[key] as BaseSeries;
          item.data.push(value);
        } else {
          resultObj[key] = {
            name: name,
            group: name,
            data: [value],
          };
        }
      }
    });
  }

  return Object.values(resultObj);
};
